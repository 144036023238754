import CopyrightIcon from "@mui/icons-material/Copyright";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import XIcon from "@mui/icons-material/X";

export default function Footer() {
    const navigate = useNavigate();
    const today = new Date();

    return (
        <footer>
            <div className="footerCol">
                <h6>Racetrack Pitstop</h6>
                <p
                    onClick={() => navigate("/apply")}
                    style={{ cursor: "pointer" }}
                >
                    Apply to Work with us
                </p>
                <p>
                    <a href="mailto:info@racetrackpitstop.co.uk">
                        <MailOutlineIcon fontSize="small" className="icon" />{" "}
                        info@racetrackpitstop.co.uk
                    </a>
                </p>
                <p>
                    <a href="tel:01413197174">
                        <CallIcon fontSize="small" className="icon" /> 0141 319
                        7174
                    </a>
                </p>
            </div>
            <div className="footerCol">
                <div className="footerRow">
                    <a
                        href="https://www.facebook.com/RaceTrack.Pitstop/"
                        target="_blanck"
                    >
                        <FacebookIcon
                            fontSize="large"
                            sx={{
                                color: "#bf1a1a",
                            }}
                        />
                    </a>
                    <a href="https://twitter.com/racetrack_uk" target="_blank">
                        {/* <TwitterIcon fontSize="large" sx={{ color: "#bf1a1a" }} /> */}
                        {/* <img
                            src={XLogo}
                            style={{
                                width: "40px",
                            }}
                        /> */}
                        <XIcon
                            fontSize="large"
                            sx={{
                                color: "black",
                                border: "1px solid #bf1a1a",
                                borderRadius: "5px",
                                backgroundColor: "#bf1a1a",
                                height: "29px",
                                width: "29px",
                                marginTop: "3px",
                            }}
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/racetrack.pitstop/"
                        target="_blank"
                    >
                        <InstagramIcon
                            fontSize="large"
                            sx={{
                                color: "#bf1a1a",
                            }}
                        />
                    </a>
                </div>
                <span>&copy; {today.getFullYear()} RaceTrack Pitstop </span>
                <p>
                    Website Designed &amp; Developed by
                    <a
                        href="https://nitrosolutions.co.uk/"
                        style={{ color: "#65c4e0", marginLeft:"5px"}}
                    >
                        Nitro Solutions
                    </a>
                </p>
            </div>
        </footer>
    );
}
