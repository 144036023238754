import React, { useEffect, useState } from 'react'
import style from './height.module.css'

export default function OpeningHours(props) {
    // Display the opening hours for a site
    const [hours, setHours] = useState({})

    useEffect(() => {
        if (props.hours.hours) {
            console.log("FOUND")
            // console.log(props.hours.hours)
            setHours(props.hours.hours)
        } else {
            console.log("NOT FOUND")
        }
    }, [props.hours])

    return (
        <div className={`container ${style.hours} `}>
            <h5>Opening Hours:</h5>
            <div className={`${style.times}`}>
                {Object.keys(hours).map(function (day, idx) {
                    return (
                        <div key={idx} className="address">
                            <p style={{ fontWeight: "800" }}>{day}:</p>
                            <p style={{ marginTop: "1%" }}>{hours[day]}</p>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}
