import logo from "../christmas/RT_XMAS_LOGO.webp";
import back from "../christmas/BANNER_2.webp";
import "./festive.scss";
import { useNavigate } from "react-router-dom";
export default function Festive() {
    const navigate = useNavigate();
    return (
        <section className="xmas">
            <img id="backImage" src={back} />
            {/* <div style={{position:"absolute", top:0}}> */}
            <h3>FESTIVE OPENING HOURS</h3>
            <div className="xmasInfo">
                <div className="indivSite">
                    <p className="store__name">IBROX</p>
                    <p>
                        X-MAS EVE <span>7AM-11PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>9AM-10PM</span>
                    </p>
                    <p>
                        BOXING DAY <span>9AM-11PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>7AM-11PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>10AM-10PM</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">BEARSDEN</p>
                    <p>
                        X-MAS EVE <span>7AM-11PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>10AM-8PM</span>
                    </p>
                    <p>
                        BOXING DAY <span>10AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>7AM-11PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>10AM-10PM</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">STRATHCLYDE</p>
                    <p>
                        X-MAS EVE <span>7AM-10PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>CLOSED</span>
                    </p>
                    <p>
                        BOXING DAY <span>10AM-11PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>7AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>CLOSED</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">CROWWOOD</p>
                    <p>
                        X-MAS EVE <span>7AM-10PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>10AM-10PM</span>
                    </p>
                    <p>
                        BOXING DAY <span>9AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>7AM-11PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>10AM-10PM</span>
                    </p>
                </div>

                <div className="indivSite">
                    <p className="store__name">BRAESIDE</p>
                    <p>
                        X-MAS EVE <span>24/7</span>
                    </p>
                    <p>
                        X-MAS DAY <span>24/7</span>
                    </p>
                    <p>
                        BOXING DAY <span>24/7</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>24/7</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>24/7</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">RUTHERGLEN</p>
                    <p>
                        X-MAS EVE <span>8AM-10PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>10AM-8PM</span>
                    </p>
                    <p>
                        BOXING DAY <span>10AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>8AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>10AM-8PM</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">DUMBARTON</p>
                    <p>
                        X-MAS EVE <span>10AM-10PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>CLOSED</span>
                    </p>
                    <p>
                        BOXING DAY <span>10AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>10AM-9PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>CLOSED</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">BRIDGETON</p>
                    <p>
                        X-MAS EVE <span>7AM-8PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>CLOSED</span>
                    </p>
                    <p>
                        BOXING DAY <span>9AM-6PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>7AM-8PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>CLOSED</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">SAUCHIEHALL</p>
                    <p>
                        X-MAS EVE <span>10AM-10PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>CLOSED</span>
                    </p>
                    <p>
                        BOXING DAY <span>12PM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>10AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>CLOSED</span>
                    </p>
                </div>
                <div className="indivSite">
                    <p className="store__name">COATBRIDGE</p>
                    <p>
                        X-MAS EVE <span>10AM-8PM</span>
                    </p>
                    <p>
                        X-MAS DAY <span>CLOSED</span>
                    </p>
                    <p>
                        BOXING DAY <span>12PM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS EVE <span>10AM-10PM</span>
                    </p>
                    <p>
                        NEW YEARS DAY <span>CLOSED</span>
                    </p>
                </div>
            </div>
        </section>
    );
}
