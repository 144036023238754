import React, { useState } from 'react'
import axios from "axios";
import Nav from "./Nav";
import Footer from "./Footer";
import './laundry.css'

export default function Application() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [postcode, setPostcode] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [address, setAddress] = useState("")
    const [position, setPosition] = useState("")
    const [site, setSite] = useState("")
    const [about, setAbout] = useState("")
    const [message, setMessage] = useState("");
    const [cv, setCv] = useState("");

    const submission = async () => {
        const data = new FormData();
        var candidate = {
            store: site,
            position: position,
            name: name,
            address: address,
            city: city,
            postcode: postcode,
            email: email,
            phone: phone,
            about: about,
            cv: cv
        }
        const json = JSON.stringify(candidate);
        data.append('file', cv);
        data.append("candidate", json);
        if (cv == "") {
            setMessage("Error Submitting Your Application. You Must Attach Your CV.")
            setTimeout(() => {
                setMessage("")
            }, 2500)
        } else {
            setMessage("We are now sending your application. Please wait as this may take a couple of minutes.")
            await axios.post("https://nitrous.rt-itservices.co.uk/racetrack/apply", data)
                .then(res => {
                    setMessage("Your application has been submitted")
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)

                }).catch(err => { console.log(err) })
        }
    }

    return (
        <div className="laundry">
            <Nav />
            <h2>Job Application Form</h2>
            <div style={{ "textAlign": "center" }}>
                <p>Site</p><select value={site} onChange={(e) => setSite(e.target.value)}>
                    <option>--- Select Store ---</option>
                    <option>Autoport</option>
                    <option>Bearsden</option>
                    <option>Braeside</option>
                    <option>Bridgeton</option>
                    <option>Coatbridge</option>
                    <option>Crowwood</option>
                    <option>Dumbarton Rd</option>
                    <option>Rutherglen</option>
                    <option>St James Rd</option>
                    <option>Wishaw</option>
                </select>
                <p>Position</p><select value={position} onChange={(e) => setPosition(e.target.value)}>
                    <option>--- Select Position ---</option>
                    <option>Customer Service Assistant</option>
                    <option>Supervisor</option>
                    <option>Manager</option>
                </select>
                <p>Name</p><input type="text" placeholder="Name...." required value={name} onChange={(e) => setName(e.target.value)} />
                <p>Email Address</p><input type="email" placeholder="Email..." required value={email} onChange={(e) => setEmail(e.target.value)} />
                <p>Phone Number</p><input type="number" placeholder="Phone Number..." required min="0" step=".01" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <p>Address</p><input type="text" placeholder="Address..." required value={address} onChange={(e) => setAddress(e.target.value)} />
                <p>City</p><input type="text" placeholder="City..." required value={city} onChange={(e) => setCity(e.target.value)} />
                <p>Postcode</p><input type="text" placeholder="Postcode..." required value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                <p>Why Work For RaceTrack?</p><textarea placeholder="Describe The Why You Would Like To Work For RaceTrack Pitstop." rows="4" value={about} onChange={(e) => setAbout(e.target.value)} /><br />
                <p>Upload your CV on PDF</p><input className='cv' type="file" name="cv" required accept=".pdf" onChange={e => { setCv(e.target.files[0]) }} /><br />
                <button onClick={submission}>Submit</button>
                <br />
                <h3 style={{ color: "white" }}>{message}</h3>
            </div>
            <Footer />
        </div>
    );
}
