import v4u from "../images/v4uLogo.webp";
import hoagies from "../images/HoagiesLogo.webp";
import tubbees from "../images/Tubbees_Logo_New.webp";
import snusDaddy from "../images/SnusDaddy.webp";
import Map from "./Map";
import Nav from "./Nav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RTW from "../images/RTW.webp";
import Snappy from "../images/snappyLogo2.png";
import Footer from "./Footer";
import awards from "../images/award_banner.png";
import awards2 from "../images/RT_AWARDS_BANNER_1920_x_900_px.png"

export default function Home() {
    const [showLocations, setShowLocations] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setShowLocations(false);
        }
    }, [window.innerWidth]);

    return (
        <div className="home">
            <header>
                <Nav />
            </header>
            <section id="about">
                <h1>who are we?</h1>
                <p>
                    We are an ambitious convenience store and petrol station
                    retailer with multiple locations across Glasgow, and we
                    continue to grow. We bring an experience like no other
                    convenience store, stepping into RaceTrack Pitstop you’ll be
                    amazed. Our stores bring you more than great everyday
                    household product range. Visiting our stores is more than
                    just a trip to the local convenience store.
                </p>
                <div className="brand">
                    <div className="detailB">
                        <a href="https://vaped4u.com/" target="_blank">
                            <img
                                src={v4u}
                                alt="Vaped4U Logo"
                                width="200px"
                            />
                        </a>
                        <div className="text">
                            <h6>Vaped4u</h6>
                            <p>
                                You can find Vaped4U within our stores, showcasing
                                an incredible variety of vapes within one place.
                                From e-liquids to pods to starter kits, Vaped4U have
                                it all.
                            </p>
                        </div>
                    </div>
                    <div className="detailB">
                        <a href="https://tubbees.co.uk/" target="_blank">
                            <img
                                src={tubbees}
                                alt="Tubbees Logo"
                                width="200px"
                            />
                        </a>
                        <div className="text">
                            <h6>Tubbees Dessert Lab</h6>
                            <p>
                                We are proud to have Tubbees Dessert Lab
                                inside our stores. Tubbees takes desserts to the
                                next level, offering a world of imported treats, a
                                variety of mouth-watering desserts and up to 96
                                flavours of slush across their stores.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="brand">
                    <div className="detailB">
                        <a href="https://snusdaddy.co.uk/" target="_blank">
                            <img
                                src={snusDaddy}
                                alt="SnusDaddy Logo"
                                width="200px"
                            />
                        </a>
                        <div className="text">
                            <h6>Snus Daddy</h6>
                            <p>
                                Brand new to our stores, SnusDaddy is one of the largest
                                retailers for Tobacco-free nicotine pouches.
                                SnusDaddy offer a large assortment of authentic Snus, legally
                                imported into the UK.
                            </p>
                        </div>
                    </div>

                    <div className="detailB">
                        <a href="https://hoagies.co.uk/" target="_blank">
                            <img
                                src={hoagies}
                                alt="Hoagies Logo"
                                width="200px"
                            />
                        </a>
                        <div className="text">
                            <h6>Hoagies</h6>
                            <p>
                                A recent addition, we now have Hoagies in our
                                stores. Fancy a hot drink? Hoagies. Fancy a
                                sandwich? Hoagies. Fancy a hot dog? Hoagies.
                                Hoagies are here for your
                                lunchtime and snacking cravings.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="findUs">
                <h2>Where can you find us?</h2>
                <div className="locations">
                    <div className="label">
                        <h3
                            onClick={() => navigate("/locations")}
                            style={{ cursor: "pointer" }}
                        >
                            Locations
                        </h3>
                        {showLocations ? (
                            <>
                                <h4>Autoport</h4>
                                <h4>Braeside</h4>
                                <h4>Bearsden</h4>
                                <h4>Bridgeton</h4>
                                <h4>Coatbridge</h4>
                                <h4>Crowwood</h4>
                                <h4>Dumbarton Rd</h4>
                                <h4>Rutherglen</h4>
                                <h4>Sauchiehall St</h4>
                                <h4>St James Rd</h4>
                                <h4>Wishaw</h4>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <Map />
                </div>
            </section>
            <section id="deals">
                <h3 className="shopOnline">SHOP ONLINE AT</h3>
                <div className="row">
                    <a href="https://racetrackwow.co.uk/" target="_blank">
                        <img src={RTW} alt="Racetrackwow logo" />
                    </a>
                    <a href="https://www.snappyshopper.co.uk/" target="_blank">
                        <img src={Snappy} alt="Snappy Shopper logo" />
                    </a>
                </div>
            </section>
            <section id="awards">
                <h2>Our Awards</h2>
                <img src={awards2} alt="awards banner" style={{width:"98%", margin:"0 auto"}}/>
            </section>
            <Footer />
        </div>
    );
}
