import React from "react";
import styles from "./storeList.module.css";

export default function Address(props) {
  // Display the address of a specific site as well as the what3words for that site
  return (
    <div className="address">
      <p>{props.address.addressLine1}</p>
      <p>{props.address.addressLine2},</p>
      <p>{props.address.addressLine3},</p>
      <p>{props.address.postcode}</p>
      <p style={{marginBottom: "2%"}}>{props.address.phoneNumber}</p>
      <div className={`col ${styles.threeWords} righttext`}>
        <a href={`https://what3words.com/${props.threeWords}`}>
          <div className="threewords">
            <span className={`${styles.threeWordsLogo}`}></span>
            {props.threeWords}
          </div>
        </a>
      </div>
    </div>
  );
}
