import React from "react";
import AtmIcon from "@mui/icons-material/Atm";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import WcIcon from "@mui/icons-material/Wc";
import Tooltip from "@mui/material/Tooltip";
import BP from "../images/bp-logo.webp";
import Evri from "../images/Evri-logo.webp";
import Lottery from "../images/National-Lottery-Logo.webp";
import TubbeesLogo from "../images/Tubbees-logo.webp";
import Gulf from "../images/gulf-logo.webp";
import PayPoint from "../images/paypoint-logo.webp";
import Subway from "../images/subway.webp";
import Wish from "../images/wish-logo.webp";
import Hoagies from "../images/HoagiesLogo-small.webp";
import InventoryIcon from "@mui/icons-material/Inventory";
import V4u from "../images/v4uLogo.webp";
import SpeedQueen from "../images/sq.png";

export default function Services(props) {
    // Display a list of services that a site provides
    return (
        <div className="container">
            <h5>Services</h5>
            <div className="servicesRow">
                {/* If the site has an ATM then show the atm icon else show nothing */}
                {props.services.atm ? (
                    <Tooltip title="ATM on Site">
                        <AtmIcon fontSize="large" sx={{ color: "#fff" }} />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {/* If the site has baby changing facilities then show the baby icon else show nothing */}
                {props.services.babyChange ? (
                    <Tooltip title="Baby Change Station">
                        <BabyChangingStationIcon
                            fontSize="large"
                            sx={{ color: "#fff" }}
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {/* If the site has parking facilities then show the parking icon else show nothing */}
                {props.services.parking ? (
                    <Tooltip title="Parking on Site">
                        <LocalParkingIcon
                            fontSize="large"
                            sx={{ color: "#fff" }}
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {/* If the site has toilets on site then show the toilet icon else show nothing */}
                {props.services.toilets ? (
                    <Tooltip title="Toilet falicities">
                        <WcIcon fontSize="large" sx={{ color: "#fff" }} />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.jetWash ? (
                    <Tooltip title="Jet Washes & Air Towers">
                        <LocalCarWashIcon
                            fontSize="large"
                            sx={{ color: "#fff" }}
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.laundry ? (
                    <Tooltip title="Laundry Machines">
                        <LocalLaundryServiceIcon
                            fontSize="large"
                            sx={{ color: "#fff" }}
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.speedQueen ? (
                    <Tooltip title="Speed Queen">
                        <img src={SpeedQueen} alt="Speed Queen" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.post ? (
                    <Tooltip title="Post Box">
                        <InventoryIcon
                            fontSize="large"
                            sx={{ color: "#be1a1a" }}
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.bp ? (
                    <Tooltip title="BP Fuel">
                        <img src={BP} alt="BP Fuel" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.tubbeesDessert ? (
                    <Tooltip title="Tubbees Dessert Lab">
                        <img
                            src={TubbeesLogo}
                            alt="Tubbees Dessert Lab"
                            height="30px"
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.tubbeesSlushee ? (
                    <Tooltip title="Tubbees Slushee">
                        <img
                            src={TubbeesLogo}
                            alt="Tubbees Slushee"
                            height="30px"
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.hermes ? (
                    <Tooltip title="Evri Parcel Shop">
                        <img src={Evri} alt="Evri Parcel Shop" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.wish ? (
                    <Tooltip title="Wish Parcel Shop">
                        <img src={Wish} alt="Wish Parcel Shop" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.lottery ? (
                    <Tooltip title="National Lottery">
                        <img
                            src={Lottery}
                            alt="National Lottery"
                            height="30px"
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.hoagies ? (
                    <Tooltip title="Hoagies Food To Go">
                        <img
                            src={Hoagies}
                            alt="Hoagies Food To Go"
                            height="30px"
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.v4u ? (
                    <Tooltip title="Vaped4U">
                        <img src={V4u} alt="Vaped4U" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.gulf ? (
                    <Tooltip title="Gulf Fuel">
                        <img src={Gulf} alt="Gulf Fuel" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.subway ? (
                    <Tooltip title="Subway">
                        <img src={Subway} alt="Subway" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.services.paypoint ? (
                    <Tooltip title="PayPoint">
                        <img src={PayPoint} alt="PayPoint" height="30px" />
                    </Tooltip>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
