import Nav from "./Nav";
import { useState, useEffect } from "react";
import axios from "axios";
import Map from "./Map";
import { SiteContext } from "../context/siteContext";
import { FilterContext } from "../context/filterContext";
import StoreList from "./StoreList";
import Footer from "./Footer";

export default function Locations() {
  const [markers, setMarkers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [brand, setBrand] = useState("");

  useEffect(() => {
    //Get Database data for the brand that is passed through
    axios
      .get("https://maps.rt-itservices.co.uk?site=RaceTrack")
      .then((res) => {
        setMarkers(res.data.sites);
        setFilterData(res.data.sites);
        setBrand(res.data.brand);
        console.log(res.data.sites);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="local">
        <Nav />
        <SiteContext.Provider value={{ markers, setMarkers }}>
          <FilterContext.Provider value={{ filterData, setFilterData }}>
            <Map />
            <StoreList />
          </FilterContext.Provider>
        </SiteContext.Provider>
      </section>
      <Footer />
    </>
  );
}
