import { useContext, useEffect, useState } from "react";
import { SiteContext } from "../context/siteContext";
import OpeningHours from "./OpeningHours";
import Services from "./Services";
import Address from "./Address";

export default function StoreList() {
  const { markers } = useContext(SiteContext);
  const [showCard, setShowCard] = useState(true);
  document.title = "RaceTrack Pitstop | Stores";

  useEffect(() => {
    if (window.innerWidth <= 750) {
      setShowCard(false);
    }
  }, [window.innerWidth]);

  function handleCard() {
    if (showCard === false) {
      setShowCard(true);
    } else {
      setShowCard(false);
    }
  }

  return (
    <section className="storeList">
      {markers.map(function (marker) {
        return (
          <div className="cards" key={marker.name}>
            <div className="card">
              <div className="cardSection">
                <h4 onClick={() => handleCard()}>{marker.name}</h4>
              </div>
              {showCard ? (
                <>
                  {marker.comingSoon === true ? (
                    <div className="cardSection">
                      <h4>COMING SOON</h4>
                      <Address
                        address={marker.Address}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="cardSection">
                        <Address
                          address={marker.Address}
                        //   threeWords={marker.threeWords}
                        />
                      </div>
                      <div className="cardSection">
                        {marker.BusinessHours.NA === true ? (
                          <></>
                        ) : (
                          <>
                            <OpeningHours hours={marker.BusinessHours} />
                            {/* If the site has buisness hours for Mon-Sat then pass the hours 
                                through to the Opening Hours Component. Else it will send a true prop to 
                                the component meaning it is open 24/7 */}
                            {/* {marker.BusinessHours.Mon_Sat ? (
                              <OpeningHours
                                open={marker.BusinessHours.opens}
                                close={marker.BusinessHours.closes}
                                openSun={marker.BusinessHours.opens}
                                closeSun={marker.BusinessHours.closes}
                              />
                            ) : (
                              <OpeningHours everyday={true} />
                            )} */}
                            <br />
                          </>
                        )}
                      </div>
                      <div className="cardSection" style={{minHeight:"119px"}}>
                        <Services services={marker.utilities} />
                      </div>
                      {/* Use the 'OtherBrands' component which displays the other brands which are found at the current site */}
                      {/* <OtherBrands brands={marker.Brands} /> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
}
