import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import drop from '../images/dropPin.webp';
// import {useState} from 'react';

export default function Map() {

  var autoport = {
    lat: 55.84992,
    lng: -4.308362,
  };
  var braeside = {
    lat: 55.902317,
    lng: -4.098607,
  };
  var bearsden = {
    lat: 55.926048,
    lng: -4.348525,
  };
  var dumbarton = {
    lat: 55.877745,
    lng: -4.347758,
  };
  var crowwood = {
    lat: 55.898631,
    lng: -4.113059,
  };
  var bridgeton = {
    lat: 55.849515,
    lng: -4.222176,
  };
  var rutherglen ={
    lat: 55.828587,
    lng: -4.208550,
  }
  var stJames = {
    lat: 55.864102,
    lng: -4.240203,
  };
  var coatbridge = {
    lat: 55.862405624433315,
    lng: -4.026688543920682
  }
  var wishaw = {
    lat: 55.775335067141754,
    lng: -3.9327532726017864,
  }
  var sauchiehall = {
    lat: 55.86617,
    lng: -4.2676,
  }
  const containerStyle = {
    width: "75%",
    height: "60vh",
    margin: "auto",
  };

  const center = {
    lat: 55.877648982180375,
    lng: -4.347763994936593,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
  });


  return isLoaded ? (
    <GoogleMap
      id="marker-example"
      mapContainerStyle={containerStyle}
      zoom={10}
      center={center}
      options={{ streetViewControl: false }}
    >
      <Marker position={autoport} icon={drop}/>
      <Marker position={braeside} icon={drop}/>
      <Marker position={bearsden} icon={drop}/>
      <Marker position={crowwood} icon={drop}/>
      <Marker position={dumbarton} icon={drop}/>
      <Marker position={bridgeton} icon={drop}/>
      <Marker position={stJames} icon={drop}/>
      <Marker position={rutherglen} icon={drop}/>
      <Marker position={coatbridge} icon={drop}/>
      <Marker position={wishaw} icon={drop}/>
      <Marker position={sauchiehall} icon={drop}/>
    </GoogleMap>
  ) : (
    <></>
  );
}
