import React, { useState } from 'react'
import axios from "axios";
import Nav from "./Nav";
import Footer from "./Footer";
import './laundry.css'
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

export default function Laundry() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [date, setDate] = useState("")
    const [amount, setAmount] = useState("")
    const [machine, setMachine] = useState("")
    const [payment, setPayment] = useState("")
    const [site, setSite] = useState("")
    const [issue, setIssue] = useState("")
    const [digits, setDigits] = useState("")
    const [message, setMessage] = useState("")
    const [showSubmissionBtn, setShowSubmissionBtn] = useState(true)

    const override = css`
    display: block;
    margin-left: 47%;
    margin-top:2%;
    margin-bottom:2%;
  `;

    const dateVerification = (datetime) => {
        var now = new Date();
        var chosen = new Date(datetime)
        if (chosen > now) {
            alert("Date & Time Must Be In The Past!")
        } else {
            setDate(datetime)
        }
    }

    const submission = () => {
        var data = { name: name, email: email, phoneNumber: phoneNumber, date: date, amount: amount, payment: payment, machine: machine, site: site, issue: issue, digits: digits };
        if (email === "" || !email.includes("@")) {
            alert("Please Enter A Valid Email Address.")
        } else if (date === "") {
            alert("Please Enter Valid Date & Time.")
        } else if (name === "" || phoneNumber === "" || amount === "" || payment === "" || payment === "--- Select Method ---" || machine === "" || site === "" || site === "--- Select Site ---" || issue === "" || payment === "Card" && digits === "") {
            alert("No Fields Should Be Left Empty!")
        } else {
            setMessage("We are now sending your submission. Please wait as this may take a minute.")
            setShowSubmissionBtn(false)
            axios.post("https://nitrous.rt-itservices.co.uk/racetrack/laundry", data).then((res) => {
                setMessage("Your Issue Has Been Submitted. You Will Be Contacted Shortly.")
                setTimeout(() => {
                    setName("");
                    setEmail("");
                    setPhoneNumber("");
                    setDate("");
                    setAmount("");
                    setMachine("");
                    setSite("");
                    setIssue("");
                    setDigits("");
                    setPayment("");
                    setShowSubmissionBtn(true);
                }, 2750)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    return (
        <div className="laundry">
            <Nav />
            <h2>Laundry Report Form</h2>
            <div style={{ "textAlign": "center" }}>
                <p>Name</p><input type="text" placeholder="Your Name...." required value={name} onChange={(e) => setName(e.target.value)} />
                <p>Email Address</p><input type="email" placeholder="Your Email..." required value={email} onChange={(e) => setEmail(e.target.value)} />
                <p>Phone Number</p><input type="text" placeholder="Your Phone Number...." required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                <p>Date &amp; Time Of Issue</p><input type="datetime-local" required value={date} onChange={(e) => dateVerification(e.target.value)} />
                <p>Amount Paid</p><input type="number" placeholder="£" required min="0" step=".01" value={amount} onChange={(e) => setAmount(e.target.value)} />
                <p>Payment Method</p>
                <select value={payment} onChange={(e) => setPayment(e.target.value)}>
                    <option>--- Select Method ---</option>
                    <option>Card</option>
                    <option>Cash</option>
                </select>
                {payment === "Card" ?
                    <>
                        <p>Last 4 Digits Of Card</p>
                        <p className='cardhelp'>If you paid via Smart Device (Smart Watch, Mobile Phone) then we will require the last 4 digits of your virtual card number.
                            You are able to get further assistance with {" "}
                            <a href="https://www.samsung.com/us/support/answer/ANS00045169/" target="_blank">
                                Samsung
                            </a> or {" "}
                            <a href="https://support.apple.com/en-us/118544#:~:text=On%20your%20iPhone,app%20and%20tap%20Apple%20Card.&text=%2C%20then%20authenticate%20with%20Face%20ID,see%20your%20virtual%20card%20number" target="_blank">
                                Apple
                            </a> devices.</p>
                        <input type="number" placeholder="Last 4 Digits Of Card...." required value={digits} onChange={(e) => setDigits(e.target.value)} />
                    </>
                    : <></>}
                <p>Machine Number</p><input type="text" placeholder="Machine Number..." required value={machine} onChange={(e) => setMachine(e.target.value)} />
                <p>Site</p><select value={site} onChange={(e) => setSite(e.target.value)}>
                    <option>--- Select Site ---</option>
                    <option>Bearsden</option>
                    <option>Crowwood</option>
                </select>
                <p>Issue</p><textarea placeholder="Describe The Issue" rows="4" value={issue} onChange={(e) => setIssue(e.target.value)} /><br />
                {showSubmissionBtn ?
                    <button onClick={submission}>Submit</button>
                    : <>
                        <br />
                        <div style={{ backgroundColor: "#8c1c21", color: "white", border: "2px solid white", width: "75%", margin: "0 auto", justifyContent: "center", alignItems: "center" }}>
                            <FadeLoader color="white" loading={!showSubmissionBtn} css={override} size={100} />
                            <h3>{message}</h3>
                        </div>
                        <br />
                    </>
                }
                {/* {showLoading ?
                     <div style={{ backgroundColor: "#8c1c21", color: "white", border: "2px solid white", width: "50%", margin: "0 auto" }}>
                         <h3>{message}</h3>
                     </div>
                     : <></>
                } */}
            </div>
            <Footer />
        </div>
    );
}
