import logo from "../images/rt-logo.webp";
import xmasLogo from "../christmas/RT_XMAS_LOGO.webp";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import Festive from "./Festive";

export default function Nav() {
    const navigate = useNavigate();
    const [locationNav, setLocationNav] = useState(false);

    useEffect(() => {
        if (
            window.location.pathname === "/locations" ||
            window.location.pathname === "/laundry" ||
            window.location.pathname === "/apply"
        ) {
            setLocationNav(true);
        }
    }, []);

    const pageTitle = (title) => {
        document.title = `RaceTrack Pitstop | ${title}`;
    };

    return (
        <div className="nav">
            <div className="navigation">
                <a
                    onClick={() => {
                        pageTitle("About Us");
                    }}
                    href="#about"
                >
                    about
                </a>
                <a
                    onClick={() => {
                        pageTitle("Stores");
                        navigate("/locations");
                    }}
                    style={{ cursor: "pointer" }}
                >
                    stores
                </a>
                <a
                    onClick={() => {
                        pageTitle("Shop");
                    }}
                    href="#deals"
                >
                    shop
                </a>
                <a
                    onClick={() => {
                        pageTitle("Awards");
                    }}
                    href="#awards"
                >
                    awards
                </a>
                <a
                    onClick={() => {
                        pageTitle("Apply");
                        navigate("/apply");
                    }}
                    style={{ cursor: "pointer" }}
                >
                    apply
                </a>
            </div>
            {locationNav ? (
                <div className="navigation">
                    <a
                        onClick={() => {
                            pageTitle("About Us");
                        }}
                        href="/#about"
                    >
                        about
                    </a>
                    <a
                        onClick={() => {
                            pageTitle("Stores");
                            navigate("/locations");
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        stores
                    </a>
                    <a
                        onClick={() => {
                            pageTitle("Shop");
                        }}
                        href="/#deals"
                    >
                        shop
                    </a>
                    <a
                        onClick={() => {
                            pageTitle("Awards");
                        }}
                        href="/#awards"
                    >
                        awards
                    </a>
                    <a
                        onClick={() => {
                            pageTitle("Apply");
                            navigate("/apply");
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        apply
                    </a>
                </div>
            ) : (
                <></>
            )}
            <div className="logo">
                <img
                    src={logo}
                    alt="RaceTrack Logo"
                    onClick={() => (window.location.href = "/")}
                    width="80%"
                    style={{ marginTop: "70px" }}
                />
            </div>
            {/* <Festive /> */}
        </div>
    );
}
